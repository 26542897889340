nav {
  height: 10%;
  animation: nav 0.2s ease-in-out forwards;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 30em;
  opacity: 0;
  padding: 3em;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  nav {
    padding: 1em;
  }
}

nav a {
  color: white;
  font-weight: 700;
  margin: 0 2em;
  letter-spacing: 0.08em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  nav a {
    margin: 0 1em;
  }
}

@keyframes navdot {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

nav a:before {
  border-radius: 50%;
  content: "";
  background-color: #ff5252;
  left: 0;
  height: 10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-200%, 0%);
  width: 10px;
}

@media screen and (max-width: 768px) {
  nav a:before {
    bottom: 150%;
    top: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

nav a.active:before {
  animation: navdot 0.25s ease-in-out forwards 0.25s;
}

@keyframes nav {
  from {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
