@import url(https://fonts.googleapis.com/css?family=Roboto);
// @import url(https://fonts.googleapis.com/css?family=Open + Sans:300, 700);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body,
html,
#root,
.App {
  height: 100%;
}

html {
  font-size: 62.5%;
  @include respond(big-desktop) {
    font-size: 75%;
  }
  @include respond(tab-land) {
    font-size: 56.25%;
  }
  @include respond(tab-port) {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
  background-color: $color-primary;
  overflow-x: hidden;
  line-height: 1.6;
  // background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEyMCc+Cgk8ZGVmcz4KCQk8cGF0dGVybiBwYXR0ZXJuVW5pdHM9J3VzZXJTcGFjZU9uVXNlJyBpZD0nYycgd2lkdGg9JzYwJyBoZWlnaHQ9JzEyMCcgeD0nMCcgeT0nMCcgdmlld0JveD0nMCAwIDUgMTAnPgoJCQk8cGF0aCBmaWxsLW9wYWNpdHk9JzAnIHN0cm9rZT0nIzI5MjkyOScgc3Ryb2tlLXdpZHRoPScwLjA5JyBkPSdNLTIsMUw3LDEwTS0yLDZMNywxNU0tMiwtNEw3LDUnLz4KCQk8L3BhdHRlcm4+CgkJPHBhdHRlcm4gcGF0dGVyblVuaXRzPSd1c2VyU3BhY2VPblVzZScgaWQ9J2MyJyB3aWR0aD0nNjAnIGhlaWdodD0nMTIwJyB4PScxMDAlJyB5PScwJyB2aWV3Qm94PScwIDAgNSAxMCc+CgkJCTxwYXRoIGZpbGwtb3BhY2l0eT0nMCcgc3Ryb2tlPScjMjkyOTI5JyBzdHJva2Utd2lkdGg9JzAuMDknIGQ9J003LDFMLTIsMTBNNyw2TC0yLDE1TTcsLTRMLTIsNScvPgoJCTwvcGF0dGVybj4KCTwvZGVmcz4KCTxyZWN0IHdpZHRoPSc1MCUnIGhlaWdodD0nMTAwJScgZmlsbD0ndXJsKCNjKScvPgoJPHJlY3QgeD0nNTAlJyB3aWR0aD0nNTAlJyBoZWlnaHQ9JzEwMCUnIGZpbGw9J3VybCgjYzIpJy8+Cjwvc3ZnPg==');
  // background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  color: $color-grey-dark-2;
  font-family: "Roboto";
  font-size: $font-size;
  // font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
::selection {
  background-color: $color-primary;
  color: $color-white;
}

// Page transitions
.page {
  left: 3rem;
  right: 3rem;
  height: 90%;
}
@keyframes moveFromRight {
  from {
    transform: translateX(100%);
  }
}

.page-exit {
  transform-origin: 0% 50%;
  position: absolute;
  top: 10%;
}

.page-enter-active {
  animation: moveFromRight 600ms ease both;
}
.page-exit-active {
  transform: rotateY(90deg);
  opacity: 0;
  transition: all 800ms ease;
}
.container {
  height: 100%;
}
.button {
  cursor: pointer;
  background: $color-grey-dark-2;
  border-radius: 6px;
  padding: 1rem 2rem;
  text-decoration: none;
  transition: box-shadow 0.3s ease;
  color: $color-primary;
  display: inline-flex;
  align-items: center;
  margin: 1rem 0;
  &:hover {
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
      0 0 20px rgba(255, 255, 255, 0.2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
  }
  &__light {
    color: $color-grey-dark-3;
    &:hover {
      box-shadow: inset 0 0 20px rgba(50, 50, 50, 0.5),
        0 0 20px rgba(50, 50, 50, 0.2);
    }
  }

  & img {
    margin-left: 4px;
  }
}
