$color-primary: #131313;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;
$color-grey-dark: #777;
$color-grey-dark-2: #e0dad2;
$color-grey-dark-3: #323232;
$color-white: #fff;
$color-black: #000;
$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eaeaea;

$color-code-green: #68d391;
$color-code-yellow: #ddca7e;
$color-code-brown: #ee6934;

$color-subtext-1: #dc6180;
$color-subtext-2: #993f4c;

$color-secondary-light: #dc9555;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #90c5f7;
$color-tertiary-dark: #0d72a1;

//FONT
$font-size: 1.4rem;
