@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flexgrid($cols, $margin) {
  $width: (100% / $cols);
  $calc-margin: (($margin * $cols) - $margin) / $cols;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &:after {
    flex: auto;
    margin: 0 auto;
    content: "";
  }
  >* {
    width: calc( #{$width} - #{$calc-margin} );
    margin-left: $margin / 2;
    margin-right: $margin/ 2;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:nth-child(#{$cols}n) {
      margin-right: 0;
    }
    &:nth-child(#{$cols}n + 1) {
      margin-left: 0;
    }
  }
}

//Media Queries
/*
0 - 600: Mobile
0 - 900: Tablet Portrait
900 - 1200: Tablet Landscape

1800+: Large Desktop
*/

/*
breakpoint args:
~ phone
~ tab-port
~ tab-land
~ big-desktop
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) { @content }; //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) { @content }; //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) { @content }; //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) { @content }; //1800px  
  }
  
}
