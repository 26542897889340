/* Variables */
$color-1: black;
$color-2: white;
$color-3: rgb(168, 50, 121);

/* Styling */
.timeline {
  margin: 4em auto;
  position: relative;
  max-width: 46em;
  font-size: $font-size;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.05em;
  list-style-type: none;
  text-align: left;
  &:before {
    background-color: $color-white;
    content: "";
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
  }
}

.timeline-event {
  position: relative;

  &:hover {
    .timeline-event-icon {
      transform: rotate(45deg);
      background-color: $color-3;
    }

    .tag__Tag {
        background-color: $color-3;
    }

    .timeline-event-thumbnail {
      -moz-box-shadow: inset 40em 0 0 0 $color-3;
      -webkit-box-shadow: inset 40em 0 0 0 $color-3;
      box-shadow: inset 40em 0 0 0 $color-3;
    }
  }
}

.timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;

  h3 {
    font-size: 1.75em;
    font-weight: 300;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 1.2em;
    font-weight: 300;
  }

  strong {
    font-weight: 700;
  }

  p:not(.timeline-event-thumbnail) {
    padding-bottom: 1.2em;
  }
}

.timeline-event-icon {
  transition: transform 0.2s ease-in;
  transform: rotate(-45deg);

  background-color: $color-white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 0;
  left: 2em;
  width: 1em;
  height: 1em;
}

.timeline-event-thumbnail {
  transition: box-shadow 0.5s ease-in 0.1s;
  color: $color-white;
  font-size: 0.75em;

  background-color: $color-black;
  box-shadow: inset 0 0 0 0em #ef795a;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}
