.infopane {
  height: 100%;
  margin: 0;
  display: grid;
  place-items: center;
}
.infopane__text {
  animation: float 5s ease-in-out infinite;
  background-color: $color-grey-light-2;
  padding: 3rem;
  border-radius: 11px;
  box-shadow: 20px 20px $color-grey-dark-2;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
