// $colors: #360745, #d61c59, #e7d84b, #efeac5, #1b8798;
$colors: $color-code-green, $color-code-yellow,  $color-code-brown, $color-tertiary-light, $color-tertiary-dark;

$max: 5;
$stroke-step: 7%;

.header {
    font-family: Arial;
    font-size: 15rem;
    z-index: 1;
    & .text-copy {
        fill: none;
        stroke: white;
        stroke-dasharray: $stroke-step $stroke-step * ($max - 1);
        stroke-width: 3px;

        animation: stroke-offset 7s linear infinite forwards;

        @for $item from 1 through $max {
            $stroke-color: nth($colors, $item);

            &:nth-child(#{$item}) {
                stroke: $stroke-color;
                stroke-dashoffset: $stroke-step * $item;
            }
        }
    }
}
@keyframes stroke-offset {
    50% {
        stroke-dashoffset: $stroke-step * $max;
    }
}
